<template>
    <div class="symbols-list">
        <div class="row">
            <SymbolsChipsListItem
                v-for="symbol in symbols"
                :key="symbol.alias"
                :symbol="symbol"
                :class="{active: symbol.id === chartActive}"
                @click.native="makeActive(symbol.id)"
            />
        </div>
    </div>
</template>

<script>
import SymbolsChipsListItem from "@/new-design/entities/Symbols/ui/SymbolsChipsListItem.vue"
import {mapGetters} from "vuex"
import {CHART_ACTIVE, MODAL, MODAL_SYMBOLS} from "@/store/mutations.type"

export default {
    name: "SymbolsChipsList",
    components: {SymbolsChipsListItem},
    props: {
        symbols: {
            type: Object,
            default: () => {
            }
        }
    },
    computed: {
        ...mapGetters(['chartActive'])
    },
    methods: {
        makeActive(id) {
            if (id === this.chartActive) {
                let gridId = undefined

                for(let n in this.gridCharts) {
                    if (this.gridCharts[n] === id) {
                        gridId = n
                        break
                    }
                }

                this.$store.commit(MODAL, {
                    symbols: true
                })
                this.$store.commit(MODAL_SYMBOLS, {
                    action: 'update',
                    id,
                    gridId
                })
                return
            }

            this.$store.commit(CHART_ACTIVE, id)
        },
    }
}
</script>

<style scoped>
.symbols-list {
    display: flex;
    align-items: center;
    min-height: 60px;
    width: 100%;
    overflow-x: auto;
}

.row {
    gap: 8px;
}
</style>
