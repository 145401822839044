<script>
export default {
    name: "BaseIconButton",
    props: {
        icon: {
            type: String,
        },
        buttonStyle: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<template>
    <button class="icon-button" :class="buttonStyle" :disabled="disabled">
        <span class="state-layer">
            <slot name="icon"/>
        </span>
    </button>
</template>

<style scoped>
.icon-button {
    cursor: pointer;
    border-radius: var(--Corner-Extra-small);
    overflow: hidden;
    display: inline-block;
}

.icon-button .state-layer {
    padding: 6px;
    display: inline-flex;
}

.icon-button.filled {
    background: var(--System-Primary);
    color: var(--System-OnPrimary);
}

.icon-button.filled:hover .state-layer {
    background: var(--Statelayers-onprimary-opacity08);
}

.icon-button.filled:focus-visible .state-layer {
    background: var(--Statelayers-onprimary-opacity12);
}

.icon-button.filled:active .state-layer {
    background: var(--Statelayers-onprimary-opacity16);
}

.icon-button.filled:disabled {
    background: var(--System-OnSurface);
    color: var(--System-OnSurface);
}

.icon-button.filled:disabled {
    background: var(--Statelayers-onsurface-opacity12);
}

.icon-button.tonal {
    background: var(--System-Surface1);
    color: var(--System-OnSurfaceVariant);
}

.icon-button.tonal:hover .state-layer {
    background: var(--Statelayers-secondarycontainer-opacity08);
}

.icon-button.tonal:focus-visible .state-layer {
    background: var(--Statelayers-secondarycontainer-opacity12);
}

.icon-button.tonal:active .state-layer {
    background: var(--Statelayers-secondarycontainer-opacity16);
}

.icon-button.tonal:disabled {
    color: var(--System-OnSurface);
}

.icon-button.tonal:disabled .state-layer {
    background: var(--System-OnSurface);
}
</style>
