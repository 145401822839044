<script>
export default {
    name: "BaseSwitchToBanner",
    props: {
        currentDesign: {
            type: String,
        },
        value: {
            type: Boolean
        }
    },
    data() {
        return {
            switchToNewDesignMessage: 'Switch to Our New Platform Design Now!',
            switchToOldDesignMessage: 'You can still use the classic design for a limited time',
            switchToOldDesignButton: 'Switch to New Design',
            switchToNewDesignButton: 'Revert to Classic Design',
        }
    },
    methods: {
        switchDesign() {
            this.$emit('input', !this.value)
            this.$emit('update', !this.value)
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<template>
    <div class="switch-to-banner">
        <div class="left">
            <button class="switch-button" @click="switchDesign">
                {{ !value ? switchToOldDesignButton : switchToNewDesignButton }}
                <svg v-if="!value" class="cursor" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                     fill="none">
                    <path
                        d="M1.6665 10V8.33333H4.1665V10H1.6665ZM4.49984 14.625L3.33317 13.4167L5.08317 11.6667L6.2915 12.8333L4.49984 14.625ZM5.08317 6.66667L3.33317 4.91667L4.49984 3.70833L6.2915 5.5L5.08317 6.66667ZM14.9998 16.6667L11.0415 12.7083L9.99984 15.8333L7.49984 7.5L15.8332 10L12.7498 11.0833L16.6665 15L14.9998 16.6667ZM8.33317 5V2.5H9.99984V5H8.33317ZM13.2498 6.66667L12.0415 5.5L13.8332 3.70833L14.9998 4.875L13.2498 6.66667Z"
                        fill="#DBE2F9"/>
                </svg>
            </button>
            <span class="message">
                {{ !value ? switchToNewDesignMessage : switchToOldDesignMessage }}
            </span>
        </div>
        <svg @click="close()" class="close" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M4.26683 12.6667L3.3335 11.7334L7.06683 8.00004L3.3335 4.26671L4.26683 3.33337L8.00016 7.06671L11.7335 3.33337L12.6668 4.26671L8.9335 8.00004L12.6668 11.7334L11.7335 12.6667L8.00016 8.93337L4.26683 12.6667Z"
            />
        </svg>
    </div>
</template>

<style scoped>
.switch-to-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 8px;
    font-size: 14px;
    font-weight: 400;
    color: var(--System-SecondaryFixed);
    background: linear-gradient(90deg, rgba(6, 27, 62, 0.70) 0%, rgba(44, 38, 79, 0.70) 48%, rgba(0, 45, 56, 0.70) 100%), var(--System-Surface5);
}

.left {
    display: flex;
    align-items: center;
}

.switch-button {
    margin-right: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    padding: 6px 16px;
    border-radius: var(--Corner-Extra-small);
    color: var(--System-SecondaryFixed);
    border: 1px solid var(--System-SecondaryFixedDim);
}

.close {
    cursor: pointer;
}

.cursor path,
.close path {
    fill: var(--System-SecondaryFixed);
}

@media (max-width: 479px) {
    .message {
        display: none;
    }
}
</style>
