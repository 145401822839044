<script>
import {isMobile} from "@/common/helpers"
import {REMOVE_ECONOMIC_CALENDAR} from "@/common/config"
import {MODAL, MODAL_MENU} from "@/store/mutations.type"
import {mapGetters} from "vuex"

export default {
    name: "SideMenu",
    data() {
        return {
            isMobile: isMobile(),
            removeEconomicCalendar: REMOVE_ECONOMIC_CALENDAR
        }
    },
    methods: {
        showMenuModal(item) {
            this.$store.commit(MODAL, {
                menu: true
            })

            let m = {
                orders: false,
                history: false,
                market: false,
                calendar: false,
                news: false
            }

            m[item] = true

            this.$store.commit(MODAL_MENU, m)
        },
        toggleWidget(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }

            if (n[widget] && !this.modals.menu) {
                this.$store.commit(MODAL, {
                    menu: true
                })
            }
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals', 'modalMenu']),
        modalMenuSplit() {
            let i = 0
            for (let n in this.modalMenu) {
                if (this.modalMenu[n]) {
                    i++
                }
            }

            return i
        }
    }
}
</script>

<template>
    <div class="side-menu" :class="{hide: isMobile}">
        <div class="menu">
            <div
                class="item"
                :class="{active: modalMenu.market, isMobile: isMobile}"
                @click="showMenuModal('market')"
            >
                <div class="icon">
                    <i class="material-symbols-outlined">lists</i>
                </div>
                <span>{{ tokens.sideMenu.marketWatch.toUpperCase() }}</span>
                <i
                    class="material-symbols-outlined toggle-btn"
                    :class="{hide: modalMenuSplit > 1 && !modalMenu.market, minus: modalMenu.market && modalMenuSplit > 0}"
                    @click.stop="toggleWidget('market')">
                    {{ modalMenu.market ? 'close' : 'add' }}
                </i>
            </div>
            <div
                class="item"
                :class="{active: modalMenu.orders, isMobile: isMobile}"
                @click="showMenuModal('orders')"
            >
                <div class="icon">
                    <i class="material-symbols-outlined">
                        orders
                    </i>
                </div>
                <span>{{ tokens.sideMenu.activeOrders.toUpperCase() }}</span>
                <i
                    class="material-symbols-outlined toggle-btn"
                    :class="{hide: modalMenuSplit > 1 && !modalMenu.orders, minus: modalMenu.orders && modalMenuSplit > 0 }"
                    @click.stop="toggleWidget('orders')"
                >{{ modalMenu.orders ? 'close' : 'add' }}
                </i>
            </div>
            <div
                class="item"
                :class="{active: modalMenu.history, isMobile: isMobile}"
                @click="showMenuModal('history')">
                <div class="icon">
                    <i class="material-symbols-outlined">history</i>
                </div>
                <span>{{ tokens.sideMenu.tradingHistory.toUpperCase() }}</span>
                <i
                    class="material-symbols-outlined toggle-btn"
                    :class="{hide: modalMenuSplit > 1 && !modalMenu.history, minus: modalMenu.history && modalMenuSplit > 0}"
                    @click.stop="toggleWidget('history')">
                    {{ modalMenu.history ? 'close' : 'add' }}
                </i>
            </div>
            <div
                v-if="!removeEconomicCalendar"
                class="item"
                :class="{active: modalMenu.calendar, isMobile: isMobile}"
                @click="showMenuModal('calendar')"
            >
                <div class="icon">
                    <i class="material-symbols-outlined">calendar_today</i>
                </div>
                <span>{{ tokens.sideMenu.economicCalendar.toUpperCase() }}</span>
                <i
                    class="material-symbols-outlined toggle-btn"
                    :class="{hide: modalMenuSplit > 1 && !modalMenu.calendar, minus: modalMenu.calendar && modalMenuSplit > 0}"
                    @click.stop="toggleWidget('calendar')"
                >
                    {{ modalMenu.calendar ? 'close' : 'add' }}
                </i>
            </div>
            <div
                class="item"
                :class="{active: modalMenu.news, isMobile: isMobile}"
                @click="showMenuModal('news')"
            >
                <div class="icon">
                    <i class="material-symbols-outlined">full_coverage</i>
                </div>
                <span>{{ tokens.sideMenu.marketNews.toUpperCase() }}</span>
                <i
                    class="material-symbols-outlined toggle-btn"
                    :class="{hide: modalMenuSplit > 1 && !modalMenu.news, minus: modalMenu.news && modalMenuSplit > 0}"
                    @click.stop="toggleWidget('news')"
                >
                    {{ modalMenu.news ? 'close' : 'add' }}
                </i>
            </div>
        </div>
    </div>
</template>

<style scoped>
.side-menu {
    flex: 0 0 72px;
    display: flex;
    align-items: stretch;
}

.menu {
    display: flex;
    flex-direction: column;
}

.menu .item {
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    font-weight: 400;
    font-size: 0.9em;
    position: relative;
    transition: background ease-in-out 0.3s, color ease-in-out 0.3s;
}

.menu .toggle-btn {
    padding: 0;
    border-radius: 100%;
    border: 2px solid var(--System-Surface);
    color: var(--System-Surface);
    background: var(--System-OnSurfaceVariant);
}

.menu .item span {
    text-align: center;
    font-weight: 500;
    font-size: 11px;
}

.icon {
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--System-Surface1);
    margin-bottom: 5px;
}

.menu .item:hover,
.menu .item.active {
    color: var(--System-Primary);
}

.menu .item:hover .toggle-btn,
.menu .item.active .toggle-btn {
    color: var(--System-Surface1);
    background: var(--System-Primary);
}

.toggle-btn {
    position: absolute;
    top: 35px;
    right: 20px;
    font-size: 11px;
    font-weight: 500;
    color: var(--System-OnSurfaceVariant);
    background: var(--System-Surface1);
    cursor: pointer;
    transition: color ease-in-out 0.3s, background ease-in-out 0.3s, opacity ease-in-out 0.3s;
}

.isMobile .menu .item {
    align-items: flex-end;
}

.isMobile .material-symbols-outlined:last-child {
    font-size: 12px
}

@media (max-width: 767px) {
    .side-menu {
        display: none;
        flex: 0 0 40px;
    }

    .menu .item {
        width: 40px;
        height: 40px;
        font-size: 0.5em;
    }

    .menu .item i {
        font-size: 1.7em;
    }
}
</style>
