<template>
    <div class="search-input">
        <div class="box">
            <i class="material-symbols-outlined">search</i>
            <input v-model="searchInput" type="text" :placeholder="placeholder" @input="updateSearch" />
        </div>
        <div v-if="searchInput" class="icons" @click="clearSearch">
            <i class="material-symbols-outlined">close</i>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseSearch",
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            searchInput: this.value
        }
    },
    methods: {
        updateSearch() {
            this.$emit('input', this.searchInput);
        },
        clearSearch() {
            this.searchInput = '';
            this.updateSearch();
        }
    }
}
</script>

<style scoped>
.search-input {
    padding: 0 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box {
    display: flex;
    align-items: center;
    width: 100%;
}

input {
    padding: 12px 8px;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    border: none;
    height: 100%;
    background: transparent;
    color: var(--System-OnSurface);
    width: 100%;
}

input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: var(--System-Outline);
}

.icons {
    cursor: pointer;
}
</style>
