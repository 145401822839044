<script>
export default {
    name: "BaseSwitch",
    props: {
        value: {
            type: [Number, Boolean],
        }
    },
    data() {
        return {
            state: this.value,
        }
    },
    methods: {
        update() {
            this.state = !this.state
            this.$emit("input", this.state)
        }
    }
}
</script>

<template>
    <div
        class="switch"
        :class="{active: state}"
        @click="update()"
    >
    </div>
</template>

<style scoped>
.switch {
    width: 34px;
    height: 24px;
    border-radius: 100px;
    background: var(--System-Surface5);
    position: relative;
    cursor: pointer;
}

.switch::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
    width: 20px;
    height: 20px;
    background: var(--System-Outline);
    border-radius: 100%;
    transition: all .3s ease-in-out;
}

.switch.active {
    background: var(--System-Tertiary);
}

.switch.active::after {
    background: var(--System-TertiaryContainer);
    left: calc(100% - 22px);
}
</style>
