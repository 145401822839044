<script>
export default {
    name: "BaseDepositButton"
}
</script>

<template>
    <button class="deposit-button">
        <div class="state-layer">
            <span class="label">Deposit</span>
            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 17" fill="none">
                <path d="M11.5711 11.2427L11.518 9.2451H7.91179L14.7426 2L13.2426 0.5L6.49758 7.83089L6.49758 4.22465L4.5 4.17161V11.2427H11.5711Z" fill="#003930"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 17V15H0L5.96047e-08 17H18Z" fill="#003930"/>
            </svg>
        </div>
    </button>
</template>

<style scoped>
.deposit-button {
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Tertiary);
    color: var(--System-OnTertiary);
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
}

.icon {
    display: none;
}

@media (max-width: 767px) {
    .deposit-button {
        padding: 6px;
    }

    .icon {
        display: block;
    }

    .label {
        display: none;
    }
}
</style>
