<template>
    <div class="widget">
        <BaseWidgetTitle :title="tokens.widgetEconomicCalendar.economicCalendar" @close="hideModal('calendar')"/>
        <div class="content">
            <div class="content">
                <iframe v-if="lightTheme" src="https://www.tradays.com/en/economic-calendar/widget?mode=2&amp;dateFormat=DMY" width="100%" height="99%" id="widgetPreview"></iframe>
                <iframe v-else src="https://www.tradays.com/en/economic-calendar/widget?mode=2&amp;dateFormat=DMY&amp;theme=1" width="100%" height="99%" id="widgetPreview"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import {MODAL, MODAL_MENU} from '@/store/mutations.type'
import {isMobile} from '@/common/helpers'
import {mapGetters} from 'vuex'
import BaseWidgetTitle from "@/new-design/shared/ui/titles/BaseWidgetTitle.vue";

export default {
    name: 'EconomicCalendar',
    components: {BaseWidgetTitle},
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        }
    },
    computed: {
        ...mapGetters(['modalMenu', 'tokens','lightTheme'])
    }
}
</script>

<style scoped>
.content {
    position: relative;
    overflow-y: scroll;
}

iframe {
    border: none;
}

</style>
