export const baseDarkTheme = {
    "--Custom-GreenContainer": "#0A3835",
    "--Custom-GreenFixed": "#15A98B",
    "--Custom-OnGreenContainer": "#00BC96",
    "--Custom-OnGreenFixed": "#CDFFF5",
    "--Custom-OnRedContainer": "#FF7385",
    "--Custom-OnRedFixed": "#FFEFEF",
    "--Custom-RedContainer": "#421D29",
    "--Custom-RedFixed": "#F14C59",
    "--Monohrome-Black": "#000000",
    "--Monohrome-White": "#FFFFFF",
    "--Statelayers-background-opacity08": "rgba(18,19,24,0.08)",
    "--Statelayers-background-opacity12": "rgba(18,19,24,0.12)",
    "--Statelayers-background-opacity16": "rgba(18,19,24,0.16)",
    "--Statelayers-error-opacity08": "rgba(255,180,171,0.08)",
    "--Statelayers-error-opacity12": "rgba(255,180,171,0.12)",
    "--Statelayers-error-opacity16": "rgba(255,180,171,0.16)",
    "--Statelayers-errorcontainer-opacity08": "rgba(147,0,10,0.08)",
    "--Statelayers-errorcontainer-opacity12": "rgba(147,0,10,0.12)",
    "--Statelayers-errorcontainer-opacity16": "rgba(147,0,10,0.16)",
    "--Statelayers-greencontainer-opacity08": "rgba(10,56,53,0.08)",
    "--Statelayers-greencontainer-opacity12": "rgba(10,56,53,0.12)",
    "--Statelayers-greencontainer-opacity16": "rgba(10,56,53,0.16)",
    "--Statelayers-greenfixed-opacity08": "rgba(21,169,139,0.08)",
    "--Statelayers-greenfixed-opacity12": "rgba(21,169,139,0.12)",
    "--Statelayers-greenfixed-opacity16": "rgba(21,169,139,0.16)",
    "--Statelayers-inverseonsurface-opacity08": "rgba(47,48,54,0.08)",
    "--Statelayers-inverseonsurface-opacity12": "rgba(47,48,54,0.12)",
    "--Statelayers-inverseonsurface-opacity16": "rgba(47,48,54,0.16)",
    "--Statelayers-inverseprimary-opacity08": "rgba(70,93,145,0.08)",
    "--Statelayers-inverseprimary-opacity12": "rgba(70,93,145,0.12)",
    "--Statelayers-inverseprimary-opacity16": "rgba(70,93,145,0.16)",
    "--Statelayers-inversesurface-opacity08": "rgba(227,226,233,0.08)",
    "--Statelayers-inversesurface-opacity12": "rgba(227,226,233,0.12)",
    "--Statelayers-inversesurface-opacity16": "rgba(227,226,233,0.16)",
    "--Statelayers-onbackground-opacity08": "rgba(226,226,233,0.08)",
    "--Statelayers-onbackground-opacity12": "rgba(226,226,233,0.12)",
    "--Statelayers-onbackground-opacity16": "rgba(226,226,233,0.16)",
    "--Statelayers-onerror-opacity08": "rgba(105,0,5,0.08)",
    "--Statelayers-onerror-opacity12": "rgba(105,0,5,0.12)",
    "--Statelayers-onerror-opacity16": "rgba(105,0,5,0.16)",
    "--Statelayers-onerrorcontainer-opacity08": "rgba(255,218,214,0.08)",
    "--Statelayers-onerrorcontainer-opacity12": "rgba(255,218,214,0.12)",
    "--Statelayers-onerrorcontainer-opacity16": "rgba(255,218,214,0.16)",
    "--Statelayers-ongreencontainer-opacity08": "rgba(0,188,150,0.08)",
    "--Statelayers-ongreencontainer-opacity12": "rgba(0,188,150,0.12)",
    "--Statelayers-ongreencontainer-opacity16": "rgba(0,188,150,0.16)",
    "--Statelayers-ongreenfixed-opacity08": "rgba(205,255,245,0.08)",
    "--Statelayers-ongreenfixed-opacity12": "rgba(205,255,245,0.12)",
    "--Statelayers-ongreenfixed-opacity16": "rgba(205,255,245,0.16)",
    "--Statelayers-onprimary-opacity08": "rgba(20,47,96,0.08)",
    "--Statelayers-onprimary-opacity12": "rgba(20,47,96,0.12)",
    "--Statelayers-onprimary-opacity16": "rgba(20,47,96,0.16)",
    "--Statelayers-onprimarycontainer-opacity08": "rgba(217,226,255,0.08)",
    "--Statelayers-onprimarycontainer-opacity12": "rgba(217,226,255,0.12)",
    "--Statelayers-onprimarycontainer-opacity16": "rgba(217,226,255,0.16)",
    "--Statelayers-onprimaryfixed-opacity08": "rgba(0,25,68,0.08)",
    "--Statelayers-onprimaryfixed-opacity12": "rgba(0,25,68,0.12)",
    "--Statelayers-onprimaryfixed-opacity16": "rgba(0,25,68,0.16)",
    "--Statelayers-onprimaryfixedvariant-opacity08": "rgba(45,69,120,0.08)",
    "--Statelayers-onprimaryfixedvariant-opacity12": "rgba(45,69,120,0.12)",
    "--Statelayers-onprimaryfixedvariant-opacity16": "rgba(45,69,120,0.16)",
    "--Statelayers-onredcontainer-opacity08": "rgba(255,115,133,0.08)",
    "--Statelayers-onredcontainer-opacity12": "rgba(255,115,133,0.12)",
    "--Statelayers-onredcontainer-opacity16": "rgba(255,115,133,0.16)",
    "--Statelayers-onredfixed-opacity08": "rgba(255,239,239,0.08)",
    "--Statelayers-onredfixed-opacity12": "rgba(255,239,239,0.12)",
    "--Statelayers-onredfixed-opacity16": "rgba(255,239,239,0.16)",
    "--Statelayers-onsecondary-opacity08": "rgba(41,48,66,0.08)",
    "--Statelayers-onsecondary-opacity12": "rgba(41,48,66,0.12)",
    "--Statelayers-onsecondary-opacity16": "rgba(41,48,66,0.16)",
    "--Statelayers-onsecondarycontainer-opacity08": "rgba(219,226,249,0.08)",
    "--Statelayers-onsecondarycontainer-opacity12": "rgba(219,226,249,0.12)",
    "--Statelayers-onsecondarycontainer-opacity16": "rgba(219,226,249,0.16)",
    "--Statelayers-onsecondaryfixed-opacity08": "rgba(20,27,44,0.08)",
    "--Statelayers-onsecondaryfixed-opacity12": "rgba(20,27,44,0.12)",
    "--Statelayers-onsecondaryfixed-opacity16": "rgba(20,27,44,0.16)",
    "--Statelayers-onsecondaryfixedvariant-opacity08": "rgba(64,70,89,0.08)",
    "--Statelayers-onsecondaryfixedvariant-opacity12": "rgba(64,70,89,0.12)",
    "--Statelayers-onsecondaryfixedvariant-opacity16": "rgba(64,70,89,0.16)",
    "--Statelayers-onsurface-opacity08": "rgba(227,226,233,0.08)",
    "--Statelayers-onsurface-opacity12": "rgba(227,226,233,0.12)",
    "--Statelayers-onsurface-opacity16": "rgba(227,226,233,0.16)",
    "--Statelayers-onsurfacevariant-opacity08": "rgba(154,157,171,0.08)",
    "--Statelayers-onsurfacevariant-opacity12": "rgba(154,157,171,0.12)",
    "--Statelayers-onsurfacevariant-opacity16": "rgba(154,157,171,0.16)",
    "--Statelayers-ontertiary-opacity08": "rgba(0,57,48,0.08)",
    "--Statelayers-ontertiary-opacity12": "rgba(0,57,48,0.12)",
    "--Statelayers-ontertiary-opacity16": "rgba(0,57,48,0.16)",
    "--Statelayers-ontertiarycontainer-opacity08": "rgba(174,242,224,0.08)",
    "--Statelayers-ontertiarycontainer-opacity12": "rgba(174,242,224,0.12)",
    "--Statelayers-ontertiarycontainer-opacity16": "rgba(174,242,224,0.16)",
    "--Statelayers-ontertiaryfixed-opacity08": "rgba(0,33,16,0.08)",
    "--Statelayers-ontertiaryfixed-opacity12": "rgba(0,33,16,0.12)",
    "--Statelayers-ontertiaryfixed-opacity16": "rgba(0,33,16,0.16)",
    "--Statelayers-ontertiaryfixedvariant-opacity08": "rgba(9,81,49,0.08)",
    "--Statelayers-ontertiaryfixedvariant-opacity12": "rgba(9,81,49,0.12)",
    "--Statelayers-ontertiaryfixedvariant-opacity16": "rgba(9,81,49,0.16)",
    "--Statelayers-outline-opacity08": "rgba(126,127,143,0.08)",
    "--Statelayers-outline-opacity12": "rgba(126,127,143,0.12)",
    "--Statelayers-outline-opacity16": "rgba(126,127,143,0.16)",
    "--Statelayers-outlinevariant-opacity08": "rgba(63,69,79,0.08)",
    "--Statelayers-outlinevariant-opacity12": "rgba(63,69,79,0.12)",
    "--Statelayers-outlinevariant-opacity16": "rgba(63,69,79,0.16)",
    "--Statelayers-primary-opacity08": "rgba(175,198,255,0.08)",
    "--Statelayers-primary-opacity12": "rgba(175,198,255,0.12)",
    "--Statelayers-primary-opacity16": "rgba(175,198,255,0.16)",
    "--Statelayers-primarycontainer-opacity08": "rgba(45,69,120,0.08)",
    "--Statelayers-primarycontainer-opacity12": "rgba(45,69,120,0.12)",
    "--Statelayers-primarycontainer-opacity16": "rgba(45,69,120,0.16)",
    "--Statelayers-primaryfixed-opacity08": "rgba(217,226,255,0.08)",
    "--Statelayers-primaryfixed-opacity12": "rgba(217,226,255,0.12)",
    "--Statelayers-primaryfixed-opacity16": "rgba(217,226,255,0.16)",
    "--Statelayers-primaryfixeddim-opacity08": "rgba(175,198,255,0.08)",
    "--Statelayers-primaryfixeddim-opacity12": "rgba(175,198,255,0.12)",
    "--Statelayers-primaryfixeddim-opacity16": "rgba(175,198,255,0.16)",
    "--Statelayers-redcontainer-opacity08": "rgba(66,29,41,0.08)",
    "--Statelayers-redcontainer-opacity12": "rgba(66,29,41,0.12)",
    "--Statelayers-redcontainer-opacity16": "rgba(66,29,41,0.16)",
    "--Statelayers-redfixed-opacity08": "rgba(241,76,89,0.08)",
    "--Statelayers-redfixed-opacity12": "rgba(241,76,89,0.12)",
    "--Statelayers-redfixed-opacity16": "rgba(241,76,89,0.16)",
    "--Statelayers-scrim-opacity08": "rgba(0,0,0,0.08)",
    "--Statelayers-scrim-opacity12": "rgba(0,0,0,0.12)",
    "--Statelayers-scrim-opacity16": "rgba(0,0,0,0.16)",
    "--Statelayers-secondary-opacity08": "rgba(191,198,220,0.08)",
    "--Statelayers-secondary-opacity12": "rgba(191,198,220,0.12)",
    "--Statelayers-secondary-opacity16": "rgba(191,198,220,0.16)",
    "--Statelayers-secondarycontainer-opacity08": "rgba(64,70,89,0.08)",
    "--Statelayers-secondarycontainer-opacity12": "rgba(64,70,89,0.12)",
    "--Statelayers-secondarycontainer-opacity16": "rgba(64,70,89,0.16)",
    "--Statelayers-secondaryfixed-opacity08": "rgba(219,226,249,0.08)",
    "--Statelayers-secondaryfixed-opacity12": "rgba(219,226,249,0.12)",
    "--Statelayers-secondaryfixed-opacity16": "rgba(219,226,249,0.16)",
    "--Statelayers-secondaryfixeddim-opacity08": "rgba(191,198,220,0.08)",
    "--Statelayers-secondaryfixeddim-opacity12": "rgba(191,198,220,0.12)",
    "--Statelayers-secondaryfixeddim-opacity16": "rgba(191,198,220,0.16)",
    "--Statelayers-shadow-opacity08": "rgba(0,0,0,0.08)",
    "--Statelayers-shadow-opacity12": "rgba(0,0,0,0.12)",
    "--Statelayers-shadow-opacity16": "rgba(0,0,0,0.16)",
    "--Statelayers-surface-opacity08": "rgba(22,27,36,0.08)",
    "--Statelayers-surface-opacity12": "rgba(22,27,36,0.12)",
    "--Statelayers-surface-opacity16": "rgba(22,27,36,0.16)",
    "--Statelayers-surface1-opacity08": "rgba(28,34,43,0.08)",
    "--Statelayers-surface1-opacity12": "rgba(28,34,43,0.12)",
    "--Statelayers-surface1-opacity16": "rgba(28,34,43,0.16)",
    "--Statelayers-surface2-opacity08": "rgba(33,39,48,0.08)",
    "--Statelayers-surface2-opacity12": "rgba(33,39,48,0.12)",
    "--Statelayers-surface2-opacity16": "rgba(33,39,48,0.16)",
    "--Statelayers-surface3-opacity08": "rgba(37,43,54,0.08)",
    "--Statelayers-surface3-opacity12": "rgba(37,43,54,0.12)",
    "--Statelayers-surface3-opacity16": "rgba(37,43,54,0.16)",
    "--Statelayers-surface4-opacity08": "rgba(41,48,60,0.08)",
    "--Statelayers-surface4-opacity12": "rgba(41,48,60,0.12)",
    "--Statelayers-surface4-opacity16": "rgba(41,48,60,0.16)",
    "--Statelayers-surface5-opacity08": "rgba(44,51,64,0.08)",
    "--Statelayers-surface5-opacity12": "rgba(44,51,64,0.12)",
    "--Statelayers-surface5-opacity16": "rgba(44,51,64,0.16)",
    "--Statelayers-surfacebright-opacity08": "rgba(56,57,63,0.08)",
    "--Statelayers-surfacebright-opacity12": "rgba(56,57,63,0.12)",
    "--Statelayers-surfacebright-opacity16": "rgba(56,57,63,0.16)",
    "--Statelayers-surfacedim-opacity08": "rgba(18,19,24,0.08)",
    "--Statelayers-surfacedim-opacity12": "rgba(18,19,24,0.12)",
    "--Statelayers-surfacedim-opacity16": "rgba(18,19,24,0.16)",
    "--Statelayers-surfacetint-opacity08": "rgba(175,198,255,0.08)",
    "--Statelayers-surfacetint-opacity12": "rgba(175,198,255,0.12)",
    "--Statelayers-surfacetint-opacity16": "rgba(175,198,255,0.16)",
    "--Statelayers-surfacevariant-opacity08": "rgba(69,70,79,0.08)",
    "--Statelayers-surfacevariant-opacity12": "rgba(69,70,79,0.12)",
    "--Statelayers-surfacevariant-opacity16": "rgba(69,70,79,0.16)",
    "--Statelayers-tertiary-opacity08": "rgba(147,213,198,0.08)",
    "--Statelayers-tertiary-opacity12": "rgba(147,213,198,0.12)",
    "--Statelayers-tertiary-opacity16": "rgba(147,213,198,0.16)",
    "--Statelayers-tertiarycontainer-opacity08": "rgba(9,81,62,0.08)",
    "--Statelayers-tertiarycontainer-opacity12": "rgba(9,81,62,0.12)",
    "--Statelayers-tertiarycontainer-opacity16": "rgba(9,81,62,0.16)",
    "--Statelayers-tertiaryfixed-opacity08": "rgba(174,242,197,0.08)",
    "--Statelayers-tertiaryfixed-opacity12": "rgba(174,242,197,0.12)",
    "--Statelayers-tertiaryfixed-opacity16": "rgba(174,242,197,0.16)",
    "--Statelayers-tertiaryfixeddim-opacity08": "rgba(147,213,170,0.08)",
    "--Statelayers-tertiaryfixeddim-opacity12": "rgba(147,213,170,0.12)",
    "--Statelayers-tertiaryfixeddim-opacity16": "rgba(147,213,170,0.16)",
    "--System-Background": "#121318",
    "--System-Error": "#FFB4AB",
    "--System-ErrorContainer": "#93000A",
    "--System-InverseOnSurface": "#2F3036",
    "--System-InversePrimary": "#465D91",
    "--System-InverseSurface": "#E3E2E9",
    "--System-OnBackground": "#E2E2E9",
    "--System-OnError": "#690005",
    "--System-OnErrorContainer": "#FFDAD6",
    "--System-OnPrimary": "#142F60",
    "--System-OnPrimaryContainer": "#D9E2FF",
    "--System-OnPrimaryFixed": "#001944",
    "--System-OnPrimaryFixedVariant": "#2D4578",
    "--System-OnSecondary": "#293042",
    "--System-OnSecondaryContainer": "#DBE2F9",
    "--System-OnSecondaryFixed": "#141B2C",
    "--System-OnSecondaryFixedVariant": "#404659",
    "--System-OnSurface": "#E3E2E9",
    "--System-OnSurfaceVariant": "#9A9DAB",
    "--System-OnTertiary": "#003930",
    "--System-OnTertiaryContainer": "#AEF2E0",
    "--System-OnTertiaryFixed": "#002110",
    "--System-OnTertiaryFixedVariant": "#095131",
    "--System-Outline": "#7E7F8F",
    "--System-OutlineVariant": "#3F454F",
    "--System-Primary": "#AFC6FF",
    "--System-PrimaryContainer": "#2D4578",
    "--System-PrimaryFixed": "#D9E2FF",
    "--System-PrimaryFixedDim": "#AFC6FF",
    "--System-Scrim": "#000000",
    "--System-Secondary": "#BFC6DC",
    "--System-SecondaryContainer": "#404659",
    "--System-SecondaryFixed": "#DBE2F9",
    "--System-SecondaryFixedDim": "#BFC6DC",
    "--System-Shadow": "#000000",
    "--System-Surface": "#161B24",
    "--System-Surface1": "#1C222B",
    "--System-Surface2": "#212730",
    "--System-Surface3": "#252B36",
    "--System-Surface4": "#29303C",
    "--System-Surface5": "#2C3340",
    "--System-SurfaceBright": "#38393F",
    "--System-SurfaceDim": "#121318",
    "--System-SurfaceTint": "#AFC6FF",
    "--System-SurfaceVariant": "#45464F",
    "--System-Tertiary": "#93D5C6",
    "--System-TertiaryContainer": "#09513E",
    "--System-TertiaryFixed": "#AEF2C5",
    "--System-TertiaryFixedDim": "#93D5AA"
}
