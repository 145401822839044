<template>
    <div class="chart body__extrasmall">
        <div class="left">next status</div>
        <div class="right" :style="widthPrecent">total {{ total }}</div>
    </div>
</template>

<script>

export default {
    name: 'NextStatusLineChart',
    props: {
        precent: {
            type: Number,
            default: 0
        },
        total: {
            type: String,
            default: ''
        },
    },
    computed: {
        widthPrecent() {
            return {
                '--width': this.precent + '%'
            }
        }
    },

}
</script>

<style scoped>
.chart {
    width: 200px;
    height: 20px;
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 0 6px;
}

.left {
    position: relative;
    text-align: start;
    padding-bottom: 5px;

}

.left:before {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--System-Tertiary);
    border-radius: 4px;
}

.right {
    position: relative;
    text-align: end;
    padding-bottom: 5px;
}

.right:before {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--System-SecondaryContainer);
    border-radius: 4px;
}

.right:after {
    content: '';
    width: var(--width);
    height: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--System-Tertiary);
    border-radius: 4px;
}
</style>