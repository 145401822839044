<script>
export default {
    name: "BaseMonogram"
}
</script>

<template>
    <button class="monogram">
        A
    </button>
</template>

<style scoped>
.monogram {
    padding: 5px;
    background: var(--System-SecondaryContainer);
    border: 3px solid #3d5977;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: var(--System-OnSecondaryContainer);
    cursor: pointer;
}
</style>
