import { render, staticRenderFns } from "./SymbolsPanel.vue?vue&type=template&id=6160ded9&scoped=true"
import script from "./SymbolsPanel.vue?vue&type=script&lang=js"
export * from "./SymbolsPanel.vue?vue&type=script&lang=js"
import style0 from "./SymbolsPanel.vue?vue&type=style&index=0&id=6160ded9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6160ded9",
  null
  
)

export default component.exports