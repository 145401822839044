<template>
    <div v-show="(isMobile && !modals.fullscreen && modals.sideMenuBurger)" 
        class="side-menu-burger"
        :class="{ mobile: isMobile }">
        <div class="scroll-menu ">
            <div class="menu-item" 
                :class="{ active: modalMenu.market }" 
                @click="openWidget('market')">
                    <i class="material-symbols-outlined">lists</i>
                    <span class="label__largeprominent">{{ tokens.sideMenu.marketWatch }}</span>
                    <i class="material-symbols-outlined toggle-btn">add</i>
            </div>
            <div class="menu-item" 
                :class="{ active: modalMenu.orders }" 
                @click="openWidget('orders')">
                    <i class="material-symbols-outlined">orders</i>
                    <span class="label__largeprominent">{{ tokens.sideMenu.activeOrders }}</span>
                    <i class="material-symbols-outlined toggle-btn">add</i>
            </div>
            <div class="menu-item" 
                :class="{ active: modalMenu.history }" 
                @click="openWidget('history')">
                    <i class="material-symbols-outlined">history</i>
                    <span class="label__largeprominent">{{ tokens.sideMenu.tradingHistory }}</span>
                    <i class="material-symbols-outlined toggle-btn">add</i>
            </div>
            <div class="menu-item" 
                :class="{ active: modalMenu.calendar }" 
                @click="openWidget('calendar')">
                    <i class="material-symbols-outlined">calendar_today</i>
                    <span class="label__largeprominent">{{ tokens.sideMenu.economicCalendar }}</span>
                    <i class="material-symbols-outlined toggle-btn">add</i>
            </div>
            <div class="menu-item" 
                :class="{ active: modalMenu.news }" 
                @click="openWidget('news')">
                    <i class="material-symbols-outlined">full_coverage</i>
                    <span class="label__largeprominent">{{ tokens.sideMenu.marketNews }}</span>
                    <i class="material-symbols-outlined toggle-btn">add</i>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MODAL, MODAL_MENU } from "@/store/mutations.type"
import { isMobile } from '@/common/helpers'

export default {
    name: "SideMenuBurger",
    data() {
        return {
            isMobile: isMobile()
        }
    },
    methods: {
        openWidget(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            for (let i in n) {
                n[i] = false
            }

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                setTimeout(() => {
                    this.$store.commit(MODAL, {
                        menuModal: true,
                        sideMenuBurger: false
                    })
                }, 0);
            }

            if (n[widget] && !this.modals.menu) {
                this.$store.commit(MODAL, {
                    menu: true
                })
            }
        }

    },
    computed: {
        ...mapGetters(['tokens', 'modals', 'modalMenu']),
    }
}
</script>

<style scoped>
.side-menu-burger {
    position: absolute;
    top: -7px;
    left: 8px;
    background: var(--System-Surface2);
    width: 200px;
    border-radius: 4px;
    z-index: 8;
    padding: 8px 0;
    color: var(--System-OnSurface)
}

.menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 12px 10px;
    position: relative;
}

.menu-item.active {
    background: #31363f;
}

.toggle-btn {
    padding: 0;
    border-radius: 100%;
    border: 2px solid var(--System-Surface);
    background: var(--System-OnSurface);
    position: absolute;
    top: 23px;
    left: 23px;
    font-size: 11px;
    font-weight: 500;
    color: var(--System-Surface1);
    cursor: pointer;
    transition: color ease-in-out 0.3s, background ease-in-out 0.3s, opacity ease-in-out 0.3s;
}
</style>
