<template>
    <div class="chat"
         :class="{over: ddOver }"
         v-cloak
         @drop.prevent="e => addFile(e)"
         @dragover.prevent
         @dragenter="over(true)"
         @dragleave="over(false)"
         @dragover="over(true)"
         @keyup.esc="toggler = false"
    >
        <div class="messages-list" id="support-messages">
            <div class="scroll">
                <h4 v-if="!Object.keys(messageChips).length" class="title title__medium">How can I assist you
                    today?</h4>
                <template v-if="!Object.keys(messageChips).length">
                    <template>
                        <MessageChipsList
                            v-for="(chips, index) in messageChips"
                            :key="index"
                            :messages="chips.chips"
                            @send-message="send"
                        >
                            <template #title>
                                {{ chips.title }}
                            </template>
                        </MessageChipsList>
                    </template>
                </template>
                <div class="message-list">
                    <MessageBox
                        v-for="message in supportMessages"
                        :key="message.time_created + Math.random()"
                        :message="message"
                    >
                        <template #copy="{message}">
                            <i @click="copy(message.text)" v-if="message.text !== ''" class="material-symbols-outlined copy">content_copy</i>
                        </template>
                    </MessageBox>
                </div>
            </div>
        </div>
        <ChatBar/>
    </div>
</template>

<script>
import MessageChipsList from "@/new-design/entities/LiveChat/ui/MessageChipsList.vue"
import ChatBar from "@/new-design/features/LiveChat/ChatBar/ui/ChatBar.vue"
import {mapGetters} from "vuex"
import MessageBox from "@/new-design/entities/LiveChat/ui/MessageBox.vue"
import {REST_ENDPOINT, STATIC_BASE} from "@/common/config"
import {NOTIFICATION, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {ATTACHMENT_UPLOAD, SUPPORT_READ, SUPPORT_SEND} from "@/store/actions.type";

export default {
    name: "LiveChat",
    components: {MessageBox, ChatBar, MessageChipsList},
    mounted() {
        setInterval(() => {
            if (this.modalFullscreen.page === 'support') {
                this.$store.dispatch(SUPPORT_READ)
            }
        }, 3000)
        setTimeout(() => {
            let container = document.getElementById("support-messages")
            container.scrollTop = container.scrollHeight
        }, 300)
    },
    data() {
        return {
            ddOver: false,
            toggler: false,
            staticBase: STATIC_BASE,
            restEndpoint: REST_ENDPOINT,
            messageChips: [
                {
                    title: 'Account Balance',
                    chips: ['Why can’t I place a trade?', 'How do I deposit funds?']
                },
                {
                    title: 'Trading Actions',
                    chips: ['How do I short sell?', 'How can I cancel a trade?', 'What is the minimum trade amount?']
                },
                {
                    title: 'Market Info',
                    chips: ['Where can I see market trends?', 'What are the top gainers today?']
                },
                {
                    title: 'Security and Settings',
                    chips: ['How do I change my password?', 'What are the security features?', 'How do I update my account info?']
                }
            ]
        }
    },
    methods: {
        over(val) {
            this.ddOver = val
        },
        addFile(e) {
            this.ddOver = false

            if (!e.dataTransfer.files) {
                return
            }

            let data = new FormData()

            data.append('file', e.dataTransfer.files[0])

            this.$store.dispatch(ATTACHMENT_UPLOAD, data)
        },
        send(message) {
            if (!message.trim()) {
                return
            }
            // send message
            if (this.user.enable_chat === 0) {
                this.$store.commit(NOTIFICATION_ERROR, this.tokens.errors.CHAT_DISABLED)
                message = ''
                this.$refs.file.value = ''
                this.preview = ''
                this.previewShow = false
                return
            }

            if (message) {
                this.$store.dispatch(SUPPORT_SEND, message)
            }

            // send attached file:
            if (this.$refs.file.files[0]) {
                if (['image/jpeg', 'image/png', 'application/pdf'].indexOf(this.$refs.file.files[0].type) > -1) {
                    let data = new FormData()
                    data.append('file', this.$refs.file.files[0])
                    this.$store.dispatch(ATTACHMENT_UPLOAD, data)
                } else {
                    this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
                }
            }

            this.$refs.file.value = ''
            this.preview = ''
            this.previewShow = false
        },
        copy(text){
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Message copied to clipboard')
                })
        },
    },
    computed: {
        ...mapGetters(['supportMessages', 'user','modalFullscreen'])
    }
}
</script>

<style scoped>
.chat {
    display: grid;
    grid-template-rows: 1fr max-content;
    overflow: hidden;
    gap: 8px;
}

.messages-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 16px;
    height: 100%;
    overflow-y: auto;
}

.title {
    color: var(--System-Secondary);
    padding: 20px 0;
}
</style>
