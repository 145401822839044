<template>
    <component :is="tag" class="menu-list-item body__medium">
        <slot name="content"/>
    </component>
</template>

<script>
export default {
    name: "BaseMenuListItem",
    props: {
        tag: {
            type: String,
            default: 'div'
        }
    }
}
</script>

<style scoped>
.menu-list-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 8px;
    background: transparent;
    color: var(--System-OnSurface);
    cursor: pointer;
}

.menu-list-item:hover {
    background: var(--Statelayers-primary-opacity16);
}

.menu-list-item.active {
    color: var(--System-OnSecondaryContainer);
    background: var(--Statelayers-primary-opacity16);
}
</style>
