import { render, staticRenderFns } from "./OrdersPane.vue?vue&type=template&id=3560cf34&scoped=true"
import script from "./OrdersPane.vue?vue&type=script&lang=js"
export * from "./OrdersPane.vue?vue&type=script&lang=js"
import style0 from "./OrdersPane.vue?vue&type=style&index=0&id=3560cf34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3560cf34",
  null
  
)

export default component.exports