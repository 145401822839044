<script>
export default {
    name: "BaseTradingButton",
    props: {
        showSum: {
            type: Boolean,
        },
        buttonText: {
            type: String,
        },
        buttonClass: {
            type: String
        }
    }
}
</script>

<template>
    <button class="trading-button" :class="buttonClass">
        <span class="state-layer">
            <span class="label__largetrading">{{buttonText}}</span>
            <span class="price label__largetrading">
                <slot name="sum"></slot>
            </span>
        </span>
    </button>
</template>

<style scoped>
.state-layer {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    border-radius: var(--Corner-Extra-small);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
}

.trading-button.sell {
    background: var(--Custom-RedContainer);
    color: var(--Custom-OnRedContainer);
}

.trading-button.buy {
    background: var(--Custom-GreenContainer);
    color: var(--Custom-OnGreenContainer);
}

.trading-button.sell:hover .state-layer {
    background: var(--Statelayers-onredcontainer-opacity08);
}

.trading-button.sell:focus .state-layer {
    background: var(--Statelayers-onredcontainer-opacity12);
}

.trading-button.sell:active .state-layer {
    background: var(--Statelayers-onredcontainer-opacity12);
}

.trading-button.buy:hover .state-layer {
    background: var(--Statelayers-ongreencontainer-opacity08);
}

.trading-button.buy:focus .state-layer {
    background: var(--Statelayers-ongreencontainer-opacity12);
}

.trading-button.buy:active .state-layer {
    background: var(--Statelayers-ongreencontainer-opacity12);
}

.disabled{
    background: var(--System-Surface5) !important;
    color: var(--System-OnSurfaceVariant) !important;
    cursor: initial;
    pointer-events: none;
}

.close {
    opacity: .5;
    pointer-events: none;
}
</style>
