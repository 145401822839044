<script>
export default {
    name: "BaseButtonSmall",
    props: {
        buttonText: {
            type: String,
        },
        icon: {
            type: String,
        },
        buttonStyle: {
            type: String,
        }
    },
}
</script>

<template>
    <button class="button-small" :class="buttonStyle">
        <div class="state-layer">
            <span class="label__medium">{{ buttonText }}</span>
            <span class="icon" v-if="icon">
                icon
            </span>
        </div>
    </button>
</template>

<style scoped>
.button-small {
    cursor: pointer;
    border-radius: var(--Corner-Extra-small);
}

.state-layer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 6px 14px;
}

.button-small.outlined-primary {
    color: var(--System-Primary);
    border: 1px solid var(--System-Outline);
}

.button-small.outlined-primary:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-small.outlined-primary:focus {
    border: 1px solid var(--System-Primary);
}

.button-small.outlined-primary:focus .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.button-small.outlined-primary:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.button-small.outlined-primary:disabled {
    color: var(--System-OnSurface);
    border: 1px solid var(--Statelayers-onsurface-opacity12);
}

.button-small.outlined-primary {
    color: var(--System-Primary);
}

.button-small.outlined-primary:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-small.outlined-primary:focus .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-small.outlined-primary:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.button-small.outlined-primary:disabled {
    color: var(--System-OnSurface);
}

.button-small.tonal-secondary {
    color: var(--System-Primary);
    background: var(--System-Surface3);
}

.button-small.tonal-secondary:hover .state-layer {
    background: var(--Statelayers-surface-opacity08);
}

.button-small.tonal-secondary:focus .state-layer {
    background: var(--Statelayers-surface-opacity12);
}

.button-small.tonal-secondary:active .state-layer {
    background: var(--Statelayers-surface-opacity16);
}

.button-small.tonal-secondary:disabled {
    color: var(--System-OnSurface);
}

.button-small.tonal-secondary:disabled .state-layer {
    background: var(--Statelayers-onsurface-opacity12);
}

.button-small.secondary-fixed {
    border: 1px solid var(--System-PrimaryFixedDim);
    color: var(--System-SecondaryFixed);
}

.button-small.secondary-fixed:hover .state-layer {
    background: var(--Statelayers-secondaryfixed-opacity08);
}

.button-small.secondary-fixed:focus .state-layer {
    background: var(--Statelayers-secondaryfixed-opacity12);
}

.button-small.secondary-fixed:active .state-layer {
    background: var(--Statelayers-secondaryfixed-opacity16);
}
.button-small.secondary-fixed:disabled  {
    border: 1px solid var(--Statelayers-onsurface-opacity12);
    color: var(--System-OnSurface);
}
</style>
