<template>
    <BaseTradingChips
        :symbol="symbol.symbol"
        :label="symbol.symbol"
        @close="closeSymbol(symbol.id)"
    />
</template>

<script>
import BaseTradingChips from "@/new-design/shared/ui/chips/BaseTradingChips.vue"
import {SYMBOL_HIDE} from "@/store/mutations.type"

export default {
    name: "SymbolsChipsListItem",
    components: {BaseTradingChips},
    props: {
        symbol: {
            type: Object,
            default: () => {
            }
        }
    },
    methods: {
        closeSymbol(id) {
            this.$store.commit(SYMBOL_HIDE, id)
        }
    }
}
</script>

<style scoped>
.trading-chips {
    width: 100%;
    display: flex;
    flex: 1 1;
}
</style>
