<template>
    <div class="search-country-select">
        <input  v-model="searchedCountry"   
                placeholder="Search country"    
                class="search-country-input body__large"    
                @focusout="chooseCountry(searchedCountry)"/>
        <i class="material-symbols-outlined">arrow_drop_down</i>
        <BaseMenuList 
            class="search-country-list" 
            :style="{'max-height': `${(maxShowed * 36) + 8}px`,
                     'display': countries.length > 0 ? '' : 'none'}">
                <template #content>
                    <BaseMenuListItem
                        v-for="(countryItem, index) in countries"
                        :key="index"
                        :class="{ active: countryItem === country }"
                        :tag="'li'"
                        @mousedown.native="chooseCountry(countryItem)">
                            <template #content>
                                {{ countryItem }}
                            </template>
                    </BaseMenuListItem>
                </template>
        </BaseMenuList>
    </div>
</template>


<script>
import { countriesList } from '@/common/helpers'
import BaseMenuList from "@/new-design/shared/ui/panels/BaseMenuList.vue"
import BaseMenuListItem from "@/new-design/shared/ui/panels/BaseMenuListItem.vue"

export default {
    name: "SearchCountrySelect",
    components: {BaseMenuList, BaseMenuListItem},
    props: {
        maxShowed: {
            type: Number,
            default: 5
        },
        value: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            searchedCountry: this.vaildatePropsValue(this.value),
        }
    },
    computed: {
        countries() {
            return Object.values(countriesList()).filter(country => country.toLowerCase().indexOf(this.searchedCountry.toLowerCase()) > -1)
        },
        country() {
            return this.vaildatePropsValue(this.value)
        }

    },
    methods: {
        chooseCountry(country) {
            console.log('mousedown', country);
            if(Object.values(countriesList()).includes(country)){
                if(this.country === country) return;
                this.searchedCountry = country
                this.$emit('update', this.searchedCountry)
            }else{
                this.searchedCountry = this.country
            }
        },
        vaildatePropsValue(value) {
            return Object.values(countriesList()).includes(value) ?  value : ''
        }
    },
    watch: {
        value() {
            this.searchedCountry = this.vaildatePropsValue(this.value)
        }
    }
}
</script>

<style scoped>

.search-country-select {
    position: relative;
}

.search-country-select i {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    transition: transform .3s ease;
}

.search-country-input:focus~i {
    transform: rotate(180deg)
}

.search-country-input {
    padding: 8px 30px 8px 8px;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid var(--System-Outline);
    background: var(--System-Surface);
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
}

.search-country-input::placeholder {
    color: var(--System-Outline);
}

.search-country-input:focus {
    border-color: var(--System-Primary);
}

.search-country-list {
    display: none;
    position: absolute;
    top: 44px;
    width: 100%;
    overflow-x: auto;
    color: var(--System-OnSurface);
    padding: 8px 0;
    border-radius: 4px;
    background: var(--System-Surface3);
    flex-direction: column;
    z-index: 20;
}

.search-country-input:focus~.search-country-list {
    display: flex;
}

.search-country-list p {
    padding: 8px 10px;
    cursor: pointer
}

.search-country-list p.active,
.search-country-list p:hover {
    background: var(--Statelayers-primary-opacity16);
}
</style>