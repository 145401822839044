<template>
    <div class="title" :class="{ cross: type === 'cross', arrow: type === 'arrow' }">

        <div class="row row-left">
            <h4 class="title__medium"> {{ title }}</h4>
        </div>
        <i class="material-icons close cross-icon" @click="close()">{{ 'close' }}</i>
        <i class="material-icons close arrow-icon" @click="close()">{{ 'arrow_back' }}</i>
    </div>
</template>

<script>
export default {
    name: "BaseModalTitle",
    props: {
        title: {
            type: String,
        },
        type: {
            type: String,
            default: ''
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.title {
    padding: 18px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--System-OnSurface);
}

.arrow-icon {
    display: none
}

@media (min-width: 767px) {
    .title.arrow {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: start;
        gap: 30px;
    }

    .title.arrow .arrow-icon {
        display: block
    }

    .title.arrow .cross-icon {
        display: none
    }
}

@media (max-width: 767px) {
    .title {
        flex-direction: column-reverse;
        align-items: start;
        gap: 16px;
    }

    .title h4 {
        font-size: 22px;
    }

    .cross-icon {
        display: none
    }

    .arrow-icon {
        display: block
    }

    .title.cross {
        padding: 18px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: var(--System-OnSurface);
    }

    .title.cross .cross-icon {
        display: block;
    }

    .title.cross .arrow-icon {
        display: none;
    }
}

.row-left {
    gap: 8px;
}

i {
    cursor: pointer;
}
</style>
