<template>
    <div class="select">
        <label class="body__medium">{{ title }}</label>
        <div class="select-input-wrapper">
            <input readonly="true"
                    class="select-input" 
                    ref="inputRef" />
            <div v-if="selectedValue.text" 
                class="select-div body__medium" 
                @click="focus">
                    <div class="account-symbol symbol" :class="`symbol-${selectedValue.currency.toUpperCase()}`"></div>   
                    <span>{{ selectedValue.currency }}</span>          
                    <span>{{ selectedValue.text }}</span>
            </div>
            <div v-else class="select-div body__medium">
                {{ placeholder }}
            </div>
            <i class="material-symbols-outlined">arrow_drop_down</i>
            <ul class="select-list"
                :style="{'max-height': `${(maxShowed * 36) + 8}px`}">
                    <li  class="body__medium" 
                        :class="{ active: option.id === selectedValue.id }" 
                        v-for="(option,index) in options"
                        :key="index" @mousedown="setOption(option)">
                            <div class="account-symbol symbol" :class="`symbol-${option.currency.toUpperCase()}`"></div>   
                            <span>{{ option.currency }}</span>          
                            <span>{{ option.text }}</span>
                    </li>
            </ul>
        </div>
    </div>
</template>


<script>

export default {
    name: "BaseTransferSelect",
    props: {
        maxShowed: {
            type: Number,
            default: 5
        },
        options: {
            type: Array
        },
        placeholder: {
            type: String,
            default: 'Nothing selected',
        },
        title: {
            type: String
        }
    },
    data() {
        return {
            selectedValue: (this.options[0])
        }
    },
    methods: {
        setOption(option) {
            this.selectedValue = option
            this.$emit('update',{ 
                id: this.selectedValue.id, 
                product:this.selectedValue.product,
                currency:this.selectedValue.currency,
             } )
        },
        focus(){
            this.$refs.inputRef.focus()
        }
    },
    watch: {
        options: {
            deep: true,
            handler(val){
                val.some((option) => {
                    if(option.id === this.selectedValue.id){
                        this.selectedValue = option
                        return true
                    }
                }) || (this.selectedValue = (this.options[0]))

                this.$emit('update',{ 
                    id: this.selectedValue.id, 
                    product:this.selectedValue.product,
                    currency:this.selectedValue.currency,
             } )
            }
        }
    }
}
</script>

<style scoped>

.select,
.select-input-wrapper {
    position: relative;
}

.select i {
    position: absolute;
    bottom: 9px;
    right: 5px;
    z-index: 1;
    transition: transform .3s ease;
}

label {
    color: var(--System-OnSurfaceVariant);
    padding-bottom: 4px;
}

.select-input:focus~i {
    transform: rotate(180deg)
}

.select-input {
    padding: 8px 30px 8px 8px;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid var(--System-Outline);
    background: transparent;
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
    cursor: pointer;
}

.select-input::placeholder {
    color: var(--System-Outline);
}

.select-input:focus {
    border-color: var(--System-Primary);
}

.select-list {
    display: none;
    position: absolute;
    top: 44px;
    width: 100%;
    overflow-x: auto;
    color: var(--System-OnSurface);
    padding: 8px 0;
    border-radius: 4px;
    background: var(--System-Surface3);
    flex-direction: column;
    z-index: 20;
}

.select-input:focus~.select-list {
    display: flex;
}

.select-list li {
    padding: 8px 10px;
    cursor: pointer;
    min-height: 36px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.select-list li span:last-child{
    margin-left: auto;
}

.select-list li.active,
.select-list li:hover {
    background: var(--Statelayers-primary-opacity16);
}

.account-symbol {
    zoom: .25
}

.select-div{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 30px);
    height: 100%;
    padding: 0 8px;
    cursor: pointer;

}

.select-div span:last-child{
    margin-left: auto;
}

</style>